import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "casinoroyalclub",
  casinoId: 74,
  GA_TRACKING:'G-0CNJ8W68T2',

  rivalChatGroupName: "Casino Royal Club",
  prettyName: "Casino Royal Club",
  contactEmail: "support@casinoroyalclub.com",
  docsEmail: "documents@casinoroyalclub.email",

  //SEO
  metaDescription: "Join Online Casino Royal Club with free spins on premium slots, live table dealer games, and casino essentials like blackjack and roulette. Earn no deposit bonuses, win real cash rewards, get welcome bonus, join to our exclusive VIP program. Double your money immediately, even in crypto!",
  logoName: "casino-royal-club-online-casino-logo.png",
  logoAltText: "Casino Royal Club online casino logo featuring elegant gold CRC letters topped with a crown popular for luxury gaming and exclusive gambling bonuses."
};


